import React from "react";


export default () => {
  return (
    <svg width="52" height="60" viewBox="0 0 52 60" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
            d="M43.4055 4.64623C47.4696 7.23121 50.7271 10.843 51.9155 15.0785C52.253 16.2812 51.5491 17.5291 50.3434 17.8657C49.1377 18.2024 47.8867 17.5003 47.5492 16.2976C46.7549 13.4667 44.4305 10.6622 40.9678 8.45974C37.5321 6.27448 33.1719 4.81588 28.6782 4.56224C24.1955 4.30922 19.666 5.25743 15.7911 7.7739C11.9381 10.2762 8.57271 14.4316 6.55769 20.8847C3.97044 29.1705 4.06978 35.7111 5.69896 40.7144C7.32318 45.7024 10.5272 49.3502 14.5037 51.7815C22.5929 56.7272 33.7081 56.5429 40.5917 52.22C41.6513 51.5546 43.051 51.872 43.718 52.929C44.3851 53.9859 44.0669 55.3821 43.0073 56.0475C34.5865 61.3357 21.6144 61.4338 12.1343 55.6376C7.32628 52.6979 3.37525 48.2187 1.38664 42.1116C-0.596996 36.0198 -0.568279 28.4972 2.22872 19.5397C4.52109 12.1984 8.4811 7.12435 13.3172 3.9836C18.1315 0.857065 23.6548 -0.251338 28.9343 0.0466503C34.2028 0.344017 39.3143 2.04403 43.4055 4.64623Z"
            fill="#8533FF" />
    </svg>


  );
};
