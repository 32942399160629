import React from "react";


export default () => {
  return (
    <svg width="74" height="40" viewBox="0 0 74 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path
          d="M63.1933 20.0307C63.2162 20.0307 63.2391 20.0306 63.262 20.0303C64.3154 20.0184 65.3031 19.7332 66.1577 19.2423C67.3627 18.5501 68.3028 17.4487 68.7885 16.1279C68.8258 16.0264 68.8605 15.9236 68.8923 15.8197C69.0618 15.2667 69.153 14.6795 69.153 14.071C69.153 13.3481 69.0243 12.6553 68.7885 12.0142C67.9509 9.73623 65.7619 8.11133 63.1933 8.11133C61.9336 8.11133 60.7651 8.50218 59.8026 9.16923C58.2503 10.2451 57.2336 12.0393 57.2336 14.071C57.2336 14.6017 57.303 15.1161 57.4331 15.6058C57.8012 16.991 58.6557 18.1779 59.8026 18.9728C60.5117 19.4642 61.3327 19.8058 62.2195 19.9515C62.5364 20.0036 62.8617 20.0307 63.1933 20.0307Z"
          fill="#F23C5B" />
        <path
          d="M45.5111 39.8247C51.1633 39.8247 55.8645 35.7531 56.8416 30.3828C56.9641 29.7098 57.028 29.0163 57.028 28.3078C57.028 28.2625 57.0278 28.2172 57.0273 28.172C57.0069 26.4086 56.5902 24.7405 55.8622 23.2526C54.5929 20.6586 52.3774 18.6121 49.6653 17.5629C49.3175 17.4283 48.9616 17.3102 48.5984 17.2094C48.0633 17.0608 47.5126 16.95 46.9493 16.8798C46.4782 16.8211 45.9982 16.7909 45.5111 16.7909C44.3254 16.7909 43.1813 16.9701 42.1048 17.3029C37.9702 18.5812 34.8309 22.1255 34.1376 26.4848C34.0432 27.0786 33.9941 27.6875 33.9941 28.3078C33.9941 28.694 34.0132 29.0758 34.0503 29.4523C34.2461 31.4368 34.9456 33.2732 36.0191 34.8321C38.096 37.8478 41.5727 39.8247 45.5111 39.8247Z"
          fill="#F23C5B" />
        <path
          d="M57.4331 15.6058C57.303 15.1161 57.2336 14.6017 57.2336 14.071C57.2336 12.0393 58.2503 10.2451 59.8026 9.16923C57.6579 10.1458 56.967 13.4941 56.8686 15.0987C56.7579 16.9057 55.4309 18.5294 52.9057 18.0473C51.4269 17.765 49.922 17.4524 48.5984 17.2094C48.9616 17.3102 49.3175 17.4283 49.6653 17.5629C52.3774 18.6121 54.5929 20.6586 55.8622 23.2526C56.5902 24.7405 57.0069 26.4086 57.0273 28.172C57.1405 26.3068 56.8871 25.0147 56.8686 23.3789C56.8416 20.9872 59.0227 20.0307 60.4707 20.0307C60.4707 20.0307 61.8141 19.9641 62.5974 20.0013C62.4702 19.9886 62.3442 19.972 62.2195 19.9515C61.3327 19.8058 60.5117 19.4642 59.8026 18.9728C58.6557 18.1779 57.8012 16.991 57.4331 15.6058Z"
          fill="#F23C5B" />
      </g>
      <g opacity="0.5">
        <path
          d="M63.1933 20.0307C63.2162 20.0307 63.2391 20.0306 63.262 20.0303C64.3154 20.0184 65.3031 19.7332 66.1577 19.2423C67.3627 18.5501 68.3028 17.4487 68.7885 16.1279C68.8258 16.0264 68.8605 15.9236 68.8923 15.8197C69.0618 15.2667 69.153 14.6795 69.153 14.071C69.153 13.3481 69.0243 12.6553 68.7885 12.0142C67.9509 9.73623 65.7619 8.11133 63.1933 8.11133C61.9336 8.11133 60.7651 8.50218 59.8026 9.16923C58.2503 10.2451 57.2336 12.0393 57.2336 14.071C57.2336 14.6017 57.303 15.1161 57.4331 15.6058C57.8012 16.991 58.6557 18.1779 59.8026 18.9728C60.5117 19.4642 61.3327 19.8058 62.2195 19.9515C62.5364 20.0036 62.8617 20.0307 63.1933 20.0307Z"
          fill="white" />
        <path
          d="M45.5111 39.8247C51.1633 39.8247 55.8645 35.7531 56.8416 30.3828C56.9641 29.7098 57.028 29.0163 57.028 28.3078C57.028 28.2625 57.0278 28.2172 57.0273 28.172C57.0069 26.4086 56.5902 24.7405 55.8622 23.2526C54.5929 20.6586 52.3774 18.6121 49.6653 17.5629C49.3175 17.4283 48.9616 17.3102 48.5984 17.2094C48.0633 17.0608 47.5126 16.95 46.9493 16.8798C46.4782 16.8211 45.9982 16.7909 45.5111 16.7909C44.3254 16.7909 43.1813 16.9701 42.1048 17.3029C37.9702 18.5812 34.8309 22.1255 34.1376 26.4848C34.0432 27.0786 33.9941 27.6875 33.9941 28.3078C33.9941 28.694 34.0132 29.0758 34.0503 29.4523C34.2461 31.4368 34.9456 33.2732 36.0191 34.8321C38.096 37.8478 41.5727 39.8247 45.5111 39.8247Z"
          fill="white" />
        <path
          d="M57.4331 15.6058C57.303 15.1161 57.2336 14.6017 57.2336 14.071C57.2336 12.0393 58.2503 10.2451 59.8026 9.16923C57.6579 10.1458 56.967 13.4941 56.8686 15.0987C56.7579 16.9057 55.4309 18.5294 52.9057 18.0473C51.4269 17.765 49.922 17.4524 48.5984 17.2094C48.9616 17.3102 49.3175 17.4283 49.6653 17.5629C52.3774 18.6121 54.5929 20.6586 55.8622 23.2526C56.5902 24.7405 57.0069 26.4086 57.0273 28.172C57.1405 26.3068 56.8871 25.0147 56.8686 23.3789C56.8416 20.9872 59.0227 20.0307 60.4707 20.0307C60.4707 20.0307 61.8141 19.9641 62.5974 20.0013C62.4702 19.9886 62.3442 19.972 62.2195 19.9515C61.3327 19.8058 60.5117 19.4642 59.8026 18.9728C58.6557 18.1779 57.8012 16.991 57.4331 15.6058Z"
          fill="white" />
      </g>
      <path
        d="M59.0776 19.069C59.1006 19.069 59.1235 19.0689 59.1463 19.0686C60.1997 19.0567 61.1875 18.7715 62.042 18.2806C63.247 17.5884 64.1871 16.4871 64.6728 15.1662C64.7102 15.0647 64.7448 14.962 64.7766 14.858C64.9461 14.305 65.0373 13.7178 65.0373 13.1093C65.0373 12.3864 64.9086 11.6936 64.6728 11.0525C63.8352 8.77455 61.6462 7.14965 59.0776 7.14965C57.8179 7.14965 56.6494 7.5405 55.6869 8.20755C54.1346 9.28337 53.1179 11.0776 53.1179 13.1093C53.1179 13.64 53.1873 14.1544 53.3174 14.6441C53.6855 16.0293 54.54 17.2163 55.6869 18.0111C56.396 18.5026 57.217 18.8441 58.1038 18.9898C58.4207 19.0419 58.746 19.069 59.0776 19.069Z"
        fill="url(#paint0_linear_2161_846)" />
      <path
        d="M6.29846 35.9338C9.40486 35.9338 11.9231 33.4156 11.9231 30.3092C11.9231 29.9581 11.8909 29.6145 11.8294 29.2812C11.8141 29.1986 11.797 29.1166 11.7782 29.0353C11.3123 27.0228 9.76626 25.4241 7.78383 24.8827C7.31065 24.7535 6.81261 24.6845 6.29846 24.6845C5.71472 24.6845 5.15176 24.7735 4.62236 24.9385C2.33456 25.6517 0.673828 27.7865 0.673828 30.3092C0.673828 33.4156 3.19206 35.9338 6.29846 35.9338Z"
        fill="url(#paint1_linear_2161_846)" />
      <path
        d="M41.3955 38.863C47.0476 38.863 51.7488 34.7914 52.7259 29.4212C52.8484 28.7481 52.9124 28.0546 52.9124 27.3461C52.9124 27.3008 52.9121 27.2555 52.9116 27.2103C52.8912 25.4469 52.4745 23.7788 51.7465 22.2909C50.4773 19.6969 48.2617 17.6504 45.5496 16.6012C45.2018 16.4666 44.8459 16.3485 44.4827 16.2477C43.9477 16.0992 43.3969 15.9883 42.8336 15.9181C42.3625 15.8594 41.8825 15.8292 41.3955 15.8292C40.2097 15.8292 39.0656 16.0084 37.9891 16.3412C33.8546 17.6195 30.7152 21.1638 30.0219 25.5231C29.9275 26.1169 29.8785 26.7258 29.8785 27.3461C29.8785 27.7324 29.8975 28.1142 29.9346 28.4906C30.1304 30.4751 30.8299 32.3116 31.9035 33.8705C33.9803 36.8861 37.457 38.863 41.3955 38.863Z"
        fill="url(#paint2_linear_2161_846)" />
      <path
        d="M18.9451 22.9603C17.6953 22.9736 16.4862 22.7546 15.3762 22.3435C15.2538 22.2981 15.1326 22.2505 15.0125 22.2005C12.3446 21.0907 10.2627 18.8549 9.36022 16.0863C9.04301 15.1131 8.87152 14.0741 8.87152 12.9951C8.87152 16.0863 10.126 17.9036 10.4791 19.1384C10.8321 20.3732 10.4248 23.1189 8.08861 24.1262C7.20794 24.506 5.55091 24.5283 4.62236 24.9385C5.15176 24.7735 5.71472 24.6845 6.29846 24.6845C6.81261 24.6845 7.31065 24.7535 7.78383 24.8827C9.76626 25.4241 11.3123 27.0228 11.7782 29.0353C11.797 29.1166 11.8141 29.1986 11.8294 29.2812C11.6818 28.2958 11.4194 27.206 11.4965 26.5123C11.6665 24.9818 12.6801 23.1454 14.3167 22.7692C15.4688 22.5043 17.2712 22.9781 18.9451 22.9603Z"
        fill="url(#paint3_linear_2161_846)" />
      <path
        d="M18.8373 3.0293C13.3334 3.0293 8.87152 7.49113 8.87152 12.9951C8.87152 14.0741 9.04301 15.1131 9.36022 16.0863C10.2627 18.8549 12.3446 21.0907 15.0125 22.2005C15.1415 22.2457 15.2631 22.2938 15.3762 22.3435C16.4862 22.7546 17.6953 22.9736 18.9451 22.9603C20.2887 22.8411 21.577 22.6757 22.8198 22.1333C24.914 21.2194 26.6333 19.6067 27.683 17.5899C28.3986 16.215 28.8031 14.6523 28.8031 12.9951C28.8031 12.189 28.7074 11.4052 28.5267 10.6546C28.242 9.47149 27.7462 8.37063 27.0814 7.39404C25.288 4.75946 22.2647 3.0293 18.8373 3.0293Z"
        fill="url(#paint4_linear_2161_846)" />
      <path
        d="M15.0125 22.2005C15.1326 22.2505 15.2538 22.2981 15.3762 22.3435C15.2631 22.2938 15.1415 22.2457 15.0125 22.2005Z"
        fill="url(#paint5_linear_2161_846)" />
      <path
        d="M37.9891 16.3412C39.0656 16.0084 40.2097 15.8292 41.3955 15.8292C41.8825 15.8292 42.3625 15.8594 42.8336 15.9181C39.2818 15.3717 35.7551 17.0596 33.8353 17.5899C31.9155 18.1201 29.3228 17.213 29.3228 14.2643C29.3228 12.1409 28.3647 8.46543 27.0814 7.39404C27.7462 8.37063 28.242 9.47149 28.5267 10.6546C28.7074 11.4052 28.8031 12.189 28.8031 12.9951C28.8031 14.6523 28.3986 16.215 27.683 17.5899C26.6333 19.6067 24.914 21.2194 22.8198 22.1333C21.577 22.6757 20.2887 22.8411 18.9451 22.9603C20.3211 22.9603 23.1083 22.3033 24.375 21.8156C27.683 20.5421 29.7484 22.4885 29.5867 24.21C29.4249 25.9315 29.5867 30.7918 31.9035 33.8705C30.8299 32.3116 30.1304 30.4751 29.9346 28.4906C29.8975 28.1142 29.8785 27.7324 29.8785 27.3461C29.8785 26.7258 29.9275 26.1169 30.0219 25.5231C30.7152 21.1638 33.8546 17.6195 37.9891 16.3412Z"
        fill="url(#paint6_linear_2161_846)" />
      <path
        d="M73.3266 25.8021C73.3266 28.484 71.1525 30.6581 68.4707 30.6581C66.2785 30.6581 64.4256 29.2055 63.822 27.2103C63.6872 26.7646 63.6147 26.2918 63.6147 25.8021C63.6147 23.1203 65.7888 20.9462 68.4707 20.9462C69.5495 20.9462 70.6006 21.3381 71.3522 21.8932C72.5499 22.7775 73.3266 24.1991 73.3266 25.8021Z"
        fill="url(#paint7_linear_2161_846)" />
      <path
        d="M53.3174 14.6441C53.1873 14.1544 53.1179 13.64 53.1179 13.1093C53.1179 11.0776 54.1346 9.28337 55.6869 8.20755C53.5422 9.18415 52.8513 12.5324 52.7529 14.1371C52.6422 15.944 51.3153 17.5677 48.79 17.0856C47.3112 16.8033 45.8063 16.4907 44.4827 16.2477C44.8459 16.3485 45.2018 16.4666 45.5496 16.6012C48.2617 17.6504 50.4773 19.6969 51.7465 22.2909C52.4745 23.7788 52.8912 25.4469 52.9116 27.2103C53.0248 25.3451 52.7714 24.053 52.7529 22.4173C52.7259 20.0255 54.9071 19.069 56.355 19.069C56.355 19.069 57.5797 19.0319 58.363 19.069C59.098 19.1039 60.2393 19.2419 60.2393 19.2419C61.6403 19.4987 64.3183 20.6698 63.822 23.2993C63.3257 25.9289 63.5479 26.3043 63.822 27.2103C63.6872 26.7646 63.6147 26.2918 63.6147 25.8021C63.6147 23.1203 65.7888 20.9462 68.4707 20.9462C69.5495 20.9462 70.6006 21.3381 71.3522 21.8932C71.2437 21.8153 70.7504 20.9462 67.0402 20.64C65.9211 20.5477 63.9265 19.1976 64.4186 16.5845C64.5595 15.8364 64.6807 15.29 64.7766 14.858C64.7448 14.962 64.7102 15.0647 64.6728 15.1662C64.1871 16.4871 63.247 17.5884 62.042 18.2806C61.1875 18.7715 60.1997 19.0567 59.1463 19.0686C59.1235 19.0689 59.1006 19.069 59.0776 19.069C58.746 19.069 58.4207 19.0419 58.1038 18.9898C57.217 18.8441 56.396 18.5026 55.6869 18.0111C54.54 17.2163 53.6855 16.0293 53.3174 14.6441Z"
        fill="url(#paint8_linear_2161_846)" />
      <circle opacity="0.5" cx="21.1701" cy="9.56654" r="9.56654" fill="#F23C5B" />
      <circle opacity="0.5" cx="21.1701" cy="9.56654" r="9.56654" fill="white" />
      <defs>
        <linearGradient id="paint0_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint1_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint2_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint3_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint4_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint5_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint6_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint7_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
        <linearGradient id="paint8_linear_2161_846" x1="0.673827" y1="20.9462" x2="56.0704" y2="20.9462"
                        gradientUnits="userSpaceOnUse">
          <stop stopColor="#FE462D" />
          <stop offset="0.520833" stopColor="#E33091" />
          <stop offset="1" stopColor="#8D34FF" />
        </linearGradient>
      </defs>
    </svg>


  );
};
