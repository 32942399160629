import React from "react";

export default ({width=Number(94), height=Number(158)}) => {
  let viewBox = (width > 94 && height > 158 ) ? () => (`0 0 ${width} ${height}`) : () => (`0 0 94 158`);
  return (
    <svg width={width} height={height} viewBox={viewBox()} className="magnifierWrap" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g className="magnifier">
      <path
        d="M32.5576 90.0778C26.8843 94.9639 2.7729 99.85 1 98.3466C6.67328 120.522 25.466 137.06 27.5935 137.06C29.2955 137.06 42.0131 127.789 48.1591 123.153C44.8497 116.012 37.0962 99.399 32.5576 90.0778Z"
        fill="#8533FF" stroke="#1F1F22" strokeWidth="0.755256" />
      <path
        d="M32.5575 90.0133C32.5575 90.0133 33.8969 85.1196 36.4579 79.9847C36.4579 79.234 48.1038 60.5344 60.5692 58.1281C70.3001 56.2497 73.5494 59.0701 71.8424 64.1396C75.9561 63.0728 80.0722 63.0686 82.0946 66.1895C83.9748 69.091 84.1948 74.9239 84.1948 74.9239C84.1948 74.9239 87.5525 77.4992 88.0553 79.9842C88.5457 82.4083 86.6018 86.1428 86.6018 86.1428C86.6018 86.1428 90.1604 88.6064 90.2729 91.0489C90.3891 93.571 86.8463 96.3982 86.8463 96.3982C86.8463 96.3982 88.9653 98.0633 89.4611 99.6457C90.1789 101.937 87.9857 105.627 87.9857 105.627C87.9857 105.627 80.3953 110.29 75.4601 113.141C70.4976 116.008 68.7818 117.508 64.1152 119.391C58.0026 121.856 47.7424 123.124 47.7424 123.124L32.5575 90.0133Z"
        fill="#F9CF6B" />
      <path
        d="M84.1948 74.9239C84.1948 74.9239 83.9748 69.091 82.0946 66.1895C80.0722 63.0686 75.9561 63.0728 71.8424 64.1396M84.1948 74.9239L64.4697 80.6772M84.1948 74.9239C84.1948 74.9239 87.5525 77.4992 88.0553 79.9842C88.5457 82.4083 86.6018 86.1428 86.6018 86.1428M64.4697 80.6772C64.4697 80.6772 54.2274 81.404 53.4777 79.5497C52.414 76.9187 56.0596 72.7057 58.0872 71.2809C59.8109 70.0696 64.4697 67.8982 64.4697 67.8982M64.4697 80.6772C64.4697 80.6772 57.4879 87.1248 59.492 90.0147C60.6983 91.7543 62.7686 91.7263 66.1172 91.2618C69.4657 90.7972 86.6018 86.1428 86.6018 86.1428M86.6018 86.1428C86.6018 86.1428 90.1604 88.6064 90.2729 91.0489C90.3891 93.571 86.8463 96.3982 86.8463 96.3982M86.8463 96.3982C86.8463 96.3982 70.5035 100.582 67.3004 100.845C65.3541 101.006 62.8719 100.947 62.4018 99.7894C61.0157 96.3771 68.332 92.6487 68.332 92.6487M86.8463 96.3982C86.8463 96.3982 88.9653 98.0633 89.4611 99.6457C90.1789 101.937 87.9857 105.627 87.9857 105.627M86.8463 96.3982L70.3001 101.693C70.3001 101.693 67.341 103.001 66.501 104.025C61.145 110.555 87.9857 105.627 87.9857 105.627M87.9857 105.627C87.9857 105.627 80.3953 110.29 75.4601 113.141C70.4976 116.008 68.7818 117.508 64.1152 119.391C58.0026 121.856 47.7424 123.124 47.7424 123.124L32.5575 90.0133C32.5575 90.0133 33.8969 85.1196 36.4579 79.9847C36.4579 79.234 48.1038 60.5344 60.5692 58.1281C70.3001 56.2497 73.5494 59.0701 71.8424 64.1396M71.8424 64.1396L68.0155 65.643"
        stroke="#1F1F22" strokeWidth="1.00312" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M82.5303 30.6922C82.5303 41.6822 73.3439 50.6107 61.9877 50.6107C50.6315 50.6107 41.4451 41.6822 41.4451 30.6922C41.4451 19.7022 50.6315 10.7736 61.9877 10.7736C73.3439 10.7736 82.5303 19.7022 82.5303 30.6922ZM61.9877 46.8557C71.1718 46.8557 78.6366 39.6297 78.6366 30.6922C78.6366 21.7546 71.1718 14.5286 61.9877 14.5286C52.8036 14.5286 45.3388 21.7546 45.3388 30.6922C45.3388 39.6297 52.8036 46.8557 61.9877 46.8557Z"
        fill="#8CC9EC" stroke="#1F1F22" strokeWidth="0.755256" />
      <path
        d="M61.2785 50.9879L62.6968 68.6525C66.5972 67.1474 69.1974 65.2681 70.143 64.5164C69.7884 58.1269 69.7884 49.1059 68.7247 49.4828C67.8739 49.7843 63.5241 50.8627 61.2785 50.9879Z"
        fill="#8CC9EC" />
      <path
        d="M77.5892 125.782L75.8163 107.366H68.3701C68.6065 111.249 69.2211 120.671 69.7884 127.286C74.0434 132.999 76.7618 128.664 77.5892 125.782Z"
        fill="#8CC9EC" />
      <path
        d="M61.2785 50.9879L62.6968 68.6525C66.5972 67.1474 69.1974 65.2681 70.143 64.5164C69.7884 58.1269 69.7884 49.1059 68.7247 49.4828C67.8739 49.7843 63.5241 50.8627 61.2785 50.9879Z"
        stroke="#1F1F22" strokeWidth="0.755256" />
      <path
        d="M77.5892 125.782L75.8163 107.366H68.3701C68.6065 111.249 69.2211 120.671 69.7884 127.286C74.0434 132.999 76.7618 128.664 77.5892 125.782Z"
        stroke="#1F1F22" strokeWidth="0.755256" />
      <path
        className="rays"
        d="M40.0038 40.8406C38.94 40.8406 30.0755 42.3441 30.7847 46.1026M42.1313 46.1026C41.0675 46.1026 36.458 51.3646 38.2309 54.7473M76.8801 10.0205C77.2347 9.26881 79.0076 1 82.9079 1M80.0713 15.2825C81.135 14.1549 86.0992 8.14125 89.2904 10.0205"
        stroke="#F9CF6B" strokeWidth="1.51051" strokeLinecap="round" />
      </g>
    </svg>

  );
};
