import React from "react";


export default () => {
  return (
    <svg width="72" height="124" viewBox="0 0 72 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2366_54217)">
        <path
          d="M18.5807 49.6937C18.5807 49.6937 25.6838 47.5249 29.1378 44.493C32.3101 41.7083 33.8643 39.5907 35.326 35.6307C37.4444 29.892 28.9803 21.1577 34.9811 19.9704C39.0821 19.159 41.7924 22.0473 44.1454 25.5027C46.9017 29.5505 45.2453 37.9944 45.2453 37.9944C45.2453 37.9944 61.4613 35.2743 63.1551 42.262C63.8112 44.9688 62.3403 49.3473 62.3403 49.3473C62.3403 49.3473 64.2463 52.2543 63.9402 54.2419C63.6416 56.1807 61.0659 58.3614 61.0659 58.3614C61.0659 58.3614 63.1609 61.2458 62.5609 63.0854C61.9414 64.9849 58.3681 66.0172 58.3681 66.0172C58.3681 66.0172 59.5598 67.8813 59.5025 69.1993C59.4196 71.1079 56.661 73.1811 56.661 73.1811C56.661 73.1811 49.437 75.1931 44.7269 74.9963C40.0298 74.8 33.0167 72.2059 33.0167 72.2059L20.1903 74.0996L18.5807 49.6937Z"
          fill="#F9CF6B" />
        <path
          d="M62.3403 49.3473C62.3403 49.3473 63.8112 44.9688 63.1551 42.262C61.4613 35.2743 45.2453 37.9944 45.2453 37.9944C45.2453 37.9944 46.9017 29.5505 44.1454 25.5027C41.7924 22.0473 39.0821 19.159 34.9811 19.9704C28.9803 21.1577 37.4444 29.892 35.326 35.6307C33.8643 39.5907 32.3101 41.7083 29.1378 44.493C25.6838 47.5249 18.5807 49.6937 18.5807 49.6937L20.1903 74.0996L33.0167 72.2059C33.0167 72.2059 40.0298 74.8 44.7269 74.9963C49.437 75.1931 56.661 73.1811 56.661 73.1811M62.3403 49.3473L45.3943 48.3668M62.3403 49.3473C62.3403 49.3473 64.2463 52.2543 63.9402 54.2419C63.6416 56.1807 61.0659 58.3614 61.0659 58.3614M45.3943 48.3668C45.3943 48.3668 40.4921 47.8485 40.0604 46.3117C39.6157 44.7286 42.0729 43.402 44.0635 42.954C45.7557 42.5732 52.7041 41.5188 52.7041 41.5188M45.3943 48.3668C45.3943 48.3668 37.9709 50.3927 38.7274 53.128C39.1827 54.7745 40.8133 55.3721 43.5686 56.0286C46.324 56.6852 61.0659 58.3614 61.0659 58.3614M61.0659 58.3614C61.0659 58.3614 63.1609 61.2458 62.5609 63.0854C61.9414 64.9849 58.3681 66.0172 58.3681 66.0172M58.3681 66.0172C58.3681 66.0172 44.3807 64.2296 41.7959 63.4681C40.2253 63.0054 38.2964 62.2205 38.254 61.2243C38.129 58.2873 44.9137 57.7156 44.9137 57.7156M58.3681 66.0172C58.3681 66.0172 59.5598 67.8813 59.5025 69.1993C59.4196 71.1079 56.661 73.1811 56.661 73.1811M58.3681 66.0172L43.9082 64.9905C43.9082 64.9905 41.2204 65.0739 40.2735 65.5804C34.2358 68.8092 56.661 73.1811 56.661 73.1811"
          stroke="black" strokeWidth="0.566499" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M55.9941 11.769C54.5648 11.8689 51.2428 12.7985 49.3888 15.7177" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M37.4552 4.19777C36.29 5.03148 33.9469 7.56332 33.8965 11.0211" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M55.4953 15.6546C53.9634 16.0081 50.3502 17.6738 48.152 21.5086" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M39.0653 7.76899C37.9171 8.36974 35.6246 10.1411 35.6398 12.4203" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.3736 28.5851C20.4786 27.7411 25.4636 26.8006 28.5632 29.791" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M20.6024 22.9907C21.8201 22.4605 24.8411 22.2127 27.1842 25.4637" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M34.0647 99.9951C34.0647 99.9951 38.0973 101.181 40.6696 100.824C43.0322 100.496 44.4226 99.9791 46.3389 98.5638C49.116 96.5129 47.8103 89.7551 51.0178 91.0613C53.2098 91.954 53.5996 94.1612 53.6449 96.5249C53.698 99.2939 50.3032 102.766 50.3032 102.766C50.3032 102.766 58.8196 106.526 57.4608 110.353C56.9344 111.836 54.8844 113.446 54.8844 113.446C54.8844 113.446 54.8877 115.411 54.1283 116.255C53.3874 117.077 51.494 117.306 51.494 117.306C51.494 117.306 51.5938 119.32 50.741 120.002C49.8604 120.706 47.8499 120.082 47.8499 120.082C47.8499 120.082 47.8376 121.333 47.403 121.937C46.7736 122.813 44.8268 122.934 44.8268 122.934C44.8268 122.934 40.7854 121.636 38.6169 120.078C36.4543 118.524 33.9369 115.117 33.9369 115.117L27.2803 112.021L34.0647 99.9951Z"
          fill="#F9CF6B" />
        <path
          d="M54.8844 113.446C54.8844 113.446 56.9344 111.836 57.4608 110.353C58.8196 106.526 50.3032 102.766 50.3032 102.766C50.3032 102.766 53.698 99.2939 53.6449 96.5249C53.5996 94.1612 53.2098 91.954 51.0178 91.0613C47.8103 89.7551 49.116 96.5129 46.3389 98.5638C44.4226 99.9791 43.0322 100.496 40.6696 100.824C38.0973 101.181 34.0647 99.9951 34.0647 99.9951L27.2803 112.021L33.9369 115.117C33.9369 115.117 36.4543 118.524 38.6169 120.078C40.7854 121.636 44.8268 122.934 44.8268 122.934M54.8844 113.446L47.1666 107.71M54.8844 113.446C54.8844 113.446 54.8877 115.411 54.1283 116.255C53.3874 117.077 51.494 117.306 51.494 117.306M47.1666 107.71C47.1666 107.71 45.0065 105.941 45.2773 105.081C45.5563 104.195 47.1295 104.333 48.2103 104.74C49.129 105.087 52.7439 106.751 52.7439 106.751M47.1666 107.71C47.1666 107.71 43.0265 106.358 42.5388 107.885C42.2452 108.804 42.8322 109.593 43.9334 110.761C45.0345 111.928 51.494 117.306 51.494 117.306M51.494 117.306C51.494 117.306 51.5938 119.32 50.741 120.002C49.8604 120.706 47.8499 120.082 47.8499 120.082M47.8499 120.082C47.8499 120.082 41.782 114.886 40.794 113.722C40.1937 113.015 39.5234 112.044 39.8113 111.561C40.6603 110.135 44.0484 111.976 44.0484 111.976M47.8499 120.082C47.8499 120.082 47.8376 121.333 47.403 121.937C46.7736 122.813 44.8268 122.934 44.8268 122.934M47.8499 120.082L41.3231 115.098C41.3231 115.098 40.0251 114.301 39.4203 114.246C35.5642 113.892 44.8268 122.934 44.8268 122.934"
          stroke="black" strokeWidth="0.566499" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M62.9643 94.0395C62.27 93.6504 60.4435 93.0698 58.6928 93.8608" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M56.6599 84.873C55.8646 84.9052 54.0047 85.3673 52.9275 86.9581" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M61.5485 95.6924C60.7293 95.3898 58.5432 95.0625 56.3535 96.1739" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M56.3196 87.0226C55.6032 86.9517 53.9985 87.0759 53.311 88.1393" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M40.3644 90.3865C41.5994 90.636 44.2016 91.7182 44.7302 94.0519" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M43.1046 88.4671C43.8318 88.5919 45.3107 89.3974 45.4084 91.6216" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M10.4266 96.4331C10.4266 96.4331 11.7198 94.08 11.8501 92.4263C11.9697 90.9073 11.8383 89.969 11.2185 88.5793C10.3202 86.5652 5.92502 86.4522 7.18021 84.6305C8.03798 83.3855 9.46844 83.4453 10.9493 83.7415C12.684 84.0884 14.3841 86.6829 14.3841 86.6829C14.3841 86.6829 17.8991 81.886 20.0999 83.259C20.9525 83.7909 21.6755 85.2908 21.6755 85.2908C21.6755 85.2908 22.9023 85.5586 23.3242 86.1481C23.7357 86.7232 23.6184 87.9358 23.6184 87.9358C23.6184 87.9358 24.8884 88.15 25.1968 88.7756C25.5153 89.4216 24.85 90.5902 24.85 90.5902C24.85 90.5902 25.6287 90.7696 25.9462 91.1238C26.406 91.6366 26.2142 92.8676 26.2142 92.8676C26.2142 92.8676 24.8501 95.2108 23.5803 96.3498C22.314 97.4856 19.843 98.5885 19.843 98.5885L16.9977 102.316L10.4266 96.4331Z"
          fill="#F9CF6B" />
        <path
          d="M21.6755 85.2908C21.6755 85.2908 20.9525 83.7909 20.0999 83.259C17.8991 81.886 14.3841 86.6829 14.3841 86.6829C14.3841 86.6829 12.684 84.0884 10.9493 83.7415C9.46844 83.4453 8.03798 83.3855 7.18021 84.6305C5.92502 86.4522 10.3202 86.5652 11.2185 88.5793C11.8383 89.969 11.9697 90.9073 11.8501 92.4263C11.7198 94.08 10.4266 96.4331 10.4266 96.4331L16.9977 102.316L19.843 98.5885C19.843 98.5885 22.314 97.4856 23.5803 96.3498C24.8501 95.2108 26.2142 92.8676 26.2142 92.8676M21.6755 85.2908L17.0383 89.3184M21.6755 85.2908C21.6755 85.2908 22.9023 85.5586 23.3242 86.1481C23.7357 86.7232 23.6184 87.9358 23.6184 87.9358M17.0383 89.3184C17.0383 89.3184 15.6376 90.4231 15.1383 90.1361C14.6238 89.8404 14.9259 88.8779 15.3286 88.2596C15.671 87.734 17.2051 85.7072 17.2051 85.7072M17.0383 89.3184C17.0383 89.3184 15.626 91.7156 16.5117 92.2294C17.0449 92.5387 17.618 92.2809 18.4973 91.7541C19.3767 91.2274 23.6184 87.9358 23.6184 87.9358M23.6184 87.9358C23.6184 87.9358 24.8884 88.15 25.1968 88.7756C25.5153 89.4216 24.85 90.5902 24.85 90.5902M24.85 90.5902C24.85 90.5902 20.7756 93.6625 19.914 94.1192C19.3904 94.3966 18.6929 94.6815 18.4307 94.4355C17.6577 93.7102 19.2712 91.8491 19.2712 91.8491M24.85 90.5902C24.85 90.5902 25.6287 90.7696 25.9462 91.1238C26.406 91.6366 26.2142 92.8676 26.2142 92.8676M24.85 90.5902L20.8451 93.978C20.8451 93.978 20.1699 94.6784 20.0522 95.0481C19.3023 97.4052 26.2142 92.8676 26.2142 92.8676"
          stroke="black" strokeWidth="0.566499" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10.6781 77.5864C10.34 77.9661 9.7271 79.0259 9.98027 80.2267" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.09408 80.2612C4.00499 80.7618 4.03796 81.9856 4.88256 82.8759" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.5148 78.6966C11.2136 79.1662 10.7093 80.485 11.1022 82.0036" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.38834 80.7686C5.24581 81.2058 5.10302 82.224 5.67205 82.7988" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.29703 91.1841C5.62216 90.4479 6.65453 88.973 8.18291 88.9636" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
        <path d="M4.47561 89.2111C4.65325 88.7746 5.35878 87.9626 6.75976 88.2069" stroke="#F9CF6B" strokeWidth="1.133"
              strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_2366_54217">
          <rect width="72" height="124" fill="white" />
        </clipPath>
      </defs>
    </svg>


  );
};
