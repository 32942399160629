import React from "react";
import './index.scss'

export default ({width=Number(12), height=Number(12)}) => {
  let viewBox = (width > 12 && height > 12 ) ? () => (`0 0 ${width} ${height}`) : () => (`0 0 12 12`);
  return (
    <svg className={'wand'} width={width} height={height} viewBox={`0 0 14 14`} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className={'ray'} d="M2.4668 8.97148C2.22617 8.97148 2.0293 8.77461 2.0293 8.53398C2.0293 8.41914 2.07578 8.30703 2.15781 8.225L3.08477 7.29805C3.2543 7.12852 3.5332 7.12852 3.70273 7.29805C3.87227 7.46758 3.87227 7.74648 3.70273 7.91602L2.77578 8.84297C2.69375 8.925 2.58164 8.97148 2.4668 8.97148Z" fill="#8533FF"/>
      <path d="M4.56677 5.04763C5.0781 4.54724 5.90114 4.55544 6.40427 5.06677L7.48435 6.14685C7.56911 6.23161 7.56911 6.37106 7.48435 6.45583L5.95583 7.98435C5.87106 8.06911 5.73161 8.06911 5.64685 7.98435L4.56677 6.90427L4.54763 6.88513C4.04724 6.3738 4.05544 5.54802 4.56677 5.04763Z" fill="#8533FF"/>
      <path d="M8.4113 7.0738L12.4965 11.159C12.5047 11.1644 12.5101 11.1726 12.5156 11.1781C13.016 11.6894 13.0078 12.5125 12.4965 13.0156C11.9851 13.516 11.1621 13.5078 10.659 12.9965L6.5738 8.9113C6.48903 8.82653 6.48903 8.68708 6.5738 8.60231L8.10231 7.0738C8.18708 6.98903 8.32653 6.98903 8.4113 7.0738Z" fill="#8533FF"/>
      <path className={'ray'} d="M6.66895 3.89336C6.66895 4.13398 6.86582 4.33086 7.10645 4.33086C7.22129 4.33086 7.3334 4.28437 7.41543 4.20234L8.34238 3.27539C8.51191 3.10586 8.51191 2.82695 8.34238 2.65742C8.17285 2.48789 7.89395 2.48789 7.72441 2.65742L6.79746 3.58437C6.71543 3.66641 6.66895 3.77852 6.66895 3.89336Z" fill="#8533FF"/>
      <path className={'ray'} d="M4.8125 3.125C4.8125 3.36563 5.00937 3.5625 5.25 3.5625C5.49063 3.5625 5.6875 3.36563 5.6875 3.125V1.8125C5.6875 1.57187 5.49063 1.375 5.25 1.375C5.00937 1.375 4.8125 1.57187 4.8125 1.8125V3.125Z" fill="#8533FF"/>
      <path className={'ray'} d="M3.08438 4.20234C3.16641 4.28437 3.27852 4.33086 3.39336 4.33086C3.51094 4.33086 3.62031 4.28437 3.70234 4.20234C3.87461 4.03281 3.87461 3.75391 3.70234 3.58438L2.77539 2.65742C2.60586 2.48789 2.32695 2.48789 2.15742 2.65742C1.98789 2.82695 1.98789 3.10586 2.15742 3.27539L3.08438 4.20234Z" fill="#8533FF"/>
      <path className={'ray'} d="M1.3125 6.1875H2.625C2.86563 6.1875 3.0625 5.99063 3.0625 5.75C3.0625 5.50937 2.86563 5.3125 2.625 5.3125H1.3125C1.07187 5.3125 0.875 5.50937 0.875 5.75C0.875 5.99063 1.07187 6.1875 1.3125 6.1875Z" fill="#8533FF"/>
    </svg>
);
};
