import React from "react";

const lineClose = () => {
  return (
      <svg width="38" height="2" viewBox="0 0 38 2" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="1" y1="1" x2="37" y2="1" stroke="#6E6D79" strokeWidth="2" strokeLinecap="round"/>
      </svg>
  );
};

export default lineClose;
