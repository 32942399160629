import React from "react";

export default () => {
  return (
    <svg width="59" height="84" className="finderLikeWrap" viewBox="0 0 59 84" fill="none"
         xmlns="http://www.w3.org/2000/svg">
      <g className="finderLike">
        <path
          d="M6.71045 56.5975C6.71045 56.5975 13.9323 52.3635 16.9274 48.1366C19.6783 44.2543 20.8291 41.5263 21.3973 36.8022C22.2207 29.9562 10.6576 22.5905 16.9274 19.7207C21.2121 17.7595 24.9386 20.2184 28.4215 23.3924C32.5014 27.1107 32.8914 36.8022 32.8914 36.8022C32.8914 36.8022 49.9615 29.5827 53.6445 36.8022C55.0712 39.5988 54.6024 44.7842 54.6024 44.7842C54.6024 44.7842 57.4519 47.4737 57.6355 49.733C57.8147 51.9369 55.5602 55.0011 55.5602 55.0011C55.5602 55.0011 58.611 57.6164 58.4337 59.7903C58.2507 62.035 54.6024 64.1006 54.6024 64.1006C54.6024 64.1006 56.3961 65.8331 56.6777 67.2934C57.0854 69.4077 54.6024 72.4018 54.6024 72.4018C54.6024 72.4018 47.2068 76.4955 41.9908 77.5103C36.7892 78.5224 28.4215 77.5103 28.4215 77.5103L14.8521 82.9381L6.71045 56.5975Z"
          fill="#F9CF6B" />
        <path
          d="M54.6024 44.7842C54.6024 44.7842 55.0712 39.5988 53.6445 36.8022C49.9615 29.5827 32.8914 36.8022 32.8914 36.8022C32.8914 36.8022 32.5014 27.1107 28.4215 23.3924C24.9386 20.2184 21.2121 17.7595 16.9274 19.7207C10.6576 22.5905 22.2207 29.9562 21.3973 36.8022C20.8291 41.5263 19.6783 44.2543 16.9274 48.1366C13.9323 52.3635 6.71045 56.5975 6.71045 56.5975L14.8521 82.9381L28.4215 77.5103C28.4215 77.5103 36.7892 78.5224 41.9908 77.5103C47.2068 76.4955 54.6024 72.4018 54.6024 72.4018M54.6024 44.7842L35.7649 48.1366M54.6024 44.7842C54.6024 44.7842 57.4519 47.4737 57.6355 49.733C57.8147 51.9369 55.5602 55.0011 55.5602 55.0011M35.7649 48.1366C35.7649 48.1366 30.2542 48.8492 29.3793 47.2769C28.478 45.6571 30.8257 43.5605 32.8914 42.5492C34.6474 41.6895 41.9908 38.7178 41.9908 38.7178M35.7649 48.1366C35.7649 48.1366 28.1544 52.2976 29.6986 55.0992C30.6281 56.7856 32.5721 57.0149 35.7649 57.0149C38.9577 57.0149 55.5602 55.0011 55.5602 55.0011M55.5602 55.0011C55.5602 55.0011 58.611 57.6164 58.4337 59.7903C58.2507 62.035 54.6024 64.1006 54.6024 64.1006M54.6024 64.1006C54.6024 64.1006 38.798 65.7951 35.7649 65.6354C33.9218 65.5384 31.6017 65.1818 31.295 64.1006C30.3905 60.9128 37.6806 58.5132 37.6806 58.5132M54.6024 64.1006C54.6024 64.1006 56.3961 65.8331 56.6777 67.2934C57.0854 69.4077 54.6024 72.4018 54.6024 72.4018M54.6024 64.1006L38.4788 66.7529C38.4788 66.7529 35.5534 67.5466 34.6474 68.3493C28.8708 73.4673 54.6024 72.4018 54.6024 72.4018"
          stroke="black" strokeWidth="0.890489" strokeLinecap="round" strokeLinejoin="round" />
      </g>
        <g className="rays">
          <path d="M37.8252 5.23746C36.2841 5.72045 32.8843 7.60774 31.6142 11.293" stroke="#F9CF6B"
                strokeWidth="1.27712"
                strokeLinecap="round" strokeLinejoin="round" />
          <path d="M15.5189 1.77942C14.4591 2.99803 12.5514 6.3864 13.3996 10.191" stroke="#F9CF6B"
                strokeWidth="1.27712"
                strokeLinecap="round" strokeLinejoin="round" />
          <path d="M38.2933 9.62829C36.706 10.4161 33.1793 13.1867 31.771 17.9659" stroke="#F9CF6B"
                strokeWidth="1.27712"
                strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18.2177 5.27448C17.1157 6.23319 15.0647 8.77443 15.6769 11.2697" stroke="#F9CF6B"
                strokeWidth="1.27712"
                strokeLinecap="round" strokeLinejoin="round" />
          <path d="M0.96789 33.5059C3.05557 32.0304 8.27593 29.6967 12.4559 32.1658" stroke="#F9CF6B"
                strokeWidth="1.27712"
                strokeLinecap="round" strokeLinejoin="round" />
          <path d="M1.9503 26.7892C3.1469 25.8896 6.39475 24.8286 9.81338 27.7812" stroke="#F9CF6B"
                strokeWidth="1.27712"
                strokeLinecap="round" strokeLinejoin="round" />
        </g>
    </svg>
  );
};
