import React from "react";


export default () => {
  return (
    <svg width="237" height="198" viewBox="0 0 237 198" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M144.259 53.8403C148.419 91.8054 153.522 125.284 157.681 163.249L120.833 178.331C120.833 178.331 49.5633 126.355 59.601 123.744C76.0258 119.471 93.4648 131.808 93.4648 131.808C93.4648 131.808 49.7542 57.5546 59.8363 59.8103C79.1421 64.1298 98.3804 107.871 98.3804 107.871C98.3804 107.871 77.0406 41.4981 83.7558 38.9185C89.9909 36.5234 119.601 101.406 119.601 101.406C119.601 101.406 101.023 18.8539 113.017 35.8224C122.906 49.8138 124.921 62.8711 129.072 75.0482C133.224 87.2253 137.193 100.31 137.193 100.31C137.193 100.31 140.1 15.8751 144.259 53.8403Z"
        fill="#F9CF6B" />
      <path
        d="M157.681 163.249C153.522 125.284 148.419 91.8054 144.259 53.8403C140.1 15.8751 137.193 100.31 137.193 100.31C137.193 100.31 133.224 87.2253 129.072 75.0482C124.921 62.8711 122.906 49.8138 113.017 35.8224C101.023 18.8539 119.601 101.406 119.601 101.406C119.601 101.406 89.9909 36.5234 83.7558 38.9185C77.0406 41.4981 98.3804 107.871 98.3804 107.871C98.3804 107.871 79.1421 64.1298 59.8363 59.8103C49.7542 57.5546 93.4648 131.808 93.4648 131.808M93.4648 131.808C93.4648 131.808 76.0258 119.471 59.601 123.744C49.5633 126.355 120.833 178.331 120.833 178.331M93.4648 131.808C93.4648 131.808 102.191 132.038 107.041 134.409C112.368 137.013 118.183 144.725 118.183 144.725"
        stroke="#1F1F22" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M35.0619 49.967C30.6327 55.6277 23.855 69.6277 32.1785 80.3422"
            stroke="#F9CF6B" strokeWidth="3.08215" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M192.623 138.308C194.723 131.434 196.019 115.934 184.402 108.925"
            stroke="#F9CF6B" strokeWidth="3.08215" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M47.8985 47.3547C41.1491 55.9805 30.8212 77.3138 43.5046 93.6407"
            stroke="#F9CF6B" strokeWidth="3.08215" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M181.584 145.358C184.785 134.883 186.759 111.264 169.057 100.583"
            stroke="#F9CF6B" strokeWidth="3.08215" strokeLinecap="round" strokeLinejoin="round" />

      <path
        d="M196.611 9.40138C197.119 8.15736 198.881 8.15736 199.389 9.40138L202.98 18.1981C203.133 18.5712 203.429 18.8674 203.802 19.0198L212.599 22.6113C213.843 23.1192 213.843 24.8808 212.599 25.3887L203.802 28.9802C203.429 29.1326 203.133 29.4288 202.98 29.8019L199.389 38.5986C198.881 39.8426 197.119 39.8426 196.611 38.5986L193.02 29.8019C192.867 29.4288 192.571 29.1326 192.198 28.9802L183.401 25.3887C182.157 24.8808 182.157 23.1192 183.401 22.6113L192.198 19.0198C192.571 18.8674 192.867 18.5712 193.02 18.1981L196.611 9.40138Z"
        fill="white" />
      <path
        d="M16.6113 148.401C17.1192 147.157 18.8808 147.157 19.3887 148.401L22.9802 157.198C23.1326 157.571 23.4288 157.867 23.8019 158.02L32.5986 161.611C33.8426 162.119 33.8426 163.881 32.5986 164.389L23.8019 167.98C23.4288 168.133 23.1326 168.429 22.9802 168.802L19.3887 177.599C18.8808 178.843 17.1192 178.843 16.6113 177.599L13.0198 168.802C12.8674 168.429 12.5712 168.133 12.1981 167.98L3.40138 164.389C2.15736 163.881 2.15736 162.119 3.40138 161.611L12.1981 158.02C12.5712 157.867 12.8674 157.571 13.0198 157.198L16.6113 148.401Z"
        fill="white" />
      <path
        d="M223.111 52.4014C223.619 51.1574 225.381 51.1574 225.889 52.4014L227.886 57.2926C228.038 57.6658 228.334 57.9619 228.707 58.1143L233.599 60.1113C234.843 60.6192 234.843 62.3808 233.599 62.8887L228.707 64.8857C228.334 65.0381 228.038 65.3342 227.886 65.7074L225.889 70.5986C225.381 71.8426 223.619 71.8426 223.111 70.5986L221.114 65.7074C220.962 65.3342 220.666 65.0381 220.293 64.8857L215.401 62.8887C214.157 62.3808 214.157 60.6192 215.401 60.1113L220.293 58.1143C220.666 57.9619 220.962 57.6658 221.114 57.2926L223.111 52.4014Z"
        fill="white" />
    </svg>


  );
};
